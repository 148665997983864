import {Component, EventEmitter, Input, Output} from '@angular/core';
import { SessionService } from 'src/app/core/session.service';
import { Company } from 'src/app/shared/model/company';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss']
})
export class CompaniesListComponent {
  @Input()
  companies: Company[];

  @Input()
  title: string;

  @Input()
  loggedIn: boolean;

  @Input()
  buttonLabel: string;

  @Output()
  chooseCompany = new EventEmitter<Company>();

  onChooseCompany(company: Company) {
    this.chooseCompany.emit(company);
  }
}
