import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, share, shareReplay, tap } from 'rxjs/operators';
import { Company, CompanyV2Service, Provider } from '../services/company-v2.service';
import { UserService } from '../services/user.service';
import { Permissions, User } from '../shared/model/user';
import { ApiService, USER_PERMISSIONS_API } from './api.service';
import { AuthService, DEFAULT_EXPIRATION_INTERVAL_HOURS } from './auth.service';
import { CustomTokenService } from '../services/custom-token.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public token$: Observable<string>;
  public authUser$: Observable<firebase.User>;
  public isLoggedIn$ = new Observable<boolean>();

  public loggedInUser$: Observable<User> = new Observable<User>();
  public companyUser: User;

  public permissions$: Observable<Permissions> = of(new Permissions());
  public permissions: Permissions = new Permissions();

  private _currentCompanyId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public readonly currentCompanyId$ = this._currentCompanyId$.asObservable().pipe(distinctUntilChanged((a, b) => a === b));
  public currentCompanyId: string;

  public currentCompany$: Observable<Company> = of(null);

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private companyV2Service: CompanyV2Service,
    private customTokenService: CustomTokenService,
  ) {
    this.token$ = this.currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => this.authService.getToken(currentCompanyId)),
    );

    this.authUser$ = this.currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => this.authService.getUser(currentCompanyId)),
    );

    this.isLoggedIn$ = this.authUser$.pipe(
      map((user) => !!user && !user.isAnonymous)
    );

    this.currentCompanyId$.subscribe((companyId) => {
      this.currentCompanyId = companyId;
    });

    this.currentCompany$ = this._currentCompanyId$.pipe(
      mergeMap((currentCompanyId) => {
        if (currentCompanyId) {
          return this.companyV2Service.getCompanyDetails(currentCompanyId);
        } else {
          return of(null);
        }
      }),
      shareReplay(),
    );

    this.permissions$ = this.currentCompany$.pipe(
      filter((currentCompany) => !!currentCompany),
      mergeMap((currentCompany) => this.api.get<Permissions>(USER_PERMISSIONS_API('company', currentCompany.id))),
      shareReplay(),
      tap((permissions) => this.permissions = permissions),
    );

    this.currentCompany$.pipe(
      filter((company) => !!company),
      mergeMap((company) => this.userService.getCompleteProfile(company.id))
    ).subscribe((user) => this.companyUser = user);

    this.authUser$.subscribe((user) => {
      if (user) {
          user.getIdTokenResult().then((decodedToken) => {
            let shouldLogout = false;

            if (decodedToken.claims?.expire_time) {
              if (!this.authService.isLoginRecent(decodedToken.claims.expire_time)) {
                shouldLogout = true;
              }
            } else {
              let expirationInterval = DEFAULT_EXPIRATION_INTERVAL_HOURS;
              if (decodedToken.claims?.expiration_interval) {
                expirationInterval = decodedToken.claims.expiration_interval;
              }
              if (!this.authService.isLoginRecent_deprecated(user.metadata.lastSignInTime, expirationInterval)) {
                shouldLogout = true;
              }
            }

            if (shouldLogout) {
              console.warn('Old credentials. Will logout');
              this.authService.logout();
            }
          });
      }
    });

    this.loggedInUser$ = this.authUser$.pipe(
      mergeMap((firebaseUser) => {
        const getUser = firebaseUser ? this.userService.getProfile() : of(null);
        return combineLatest([of(firebaseUser), getUser]);
      }),
      map(([firebaseUser, user]: [firebase.User, User]) => {
        if (user) {
          user.last_sign_in = firebaseUser.metadata.lastSignInTime;
        }
        return user;
      }),
      shareReplay(1)
    );
  }

  customTokenSignin(company: Company): Observable<boolean> {
    return this.customTokenService.create(this.currentCompanyId, company.organization_id).pipe(
      mergeMap((response) => {
        return this.authService.signInWithCustomToken(company.id, company.providers[0].auth_tenant_id, response.token).pipe(
          map((result) => {
            return !!result.user;
          }),
          catchError((error) => {
            console.error(error);
            return of(false);
          }),
        )
      })
    )
  }

  samlSignin(companyId: string, provider: Provider): Observable<boolean> {
    return this.authService.signInWithRedirect(companyId, provider.auth_tenant_id, provider.id).pipe(
      map(() => true),
      catchError((error) => {
        console.error(error);
        return of(false);
      })
    )
  }

  automaticallySignin(companyId: string): Observable<boolean> {
    return this.authService.isLoggedIn().pipe(
      mergeMap((isLoggedIn) => {
        if (isLoggedIn) {
          this._currentCompanyId$.next(null);
        }

        return this.companyV2Service.listCompanies().pipe(
          mergeMap((companies) => {
            const company = companies.items.find((company) => company.id === companyId);
            if (!company) {
              return of(false);
            }

            return this.authUser$.pipe(
              mergeMap((authUser) => {
                if (/@closecare.com.br$/.test(authUser.email)) {
                  return this.customTokenSignin(company);
                }

                const domain = authUser.email.split('@')[1];
                const provider = company.providers.find((provider) => provider.domains.includes(domain));
                if (!provider) {
                  return of(false);
                }

                if (provider.type === 'saml') {
                  return this.samlSignin(companyId, provider);
                }

                return this.customTokenSignin(company);
              })
            )
          })
        );
      })
    )
  }

  changeCurrentCompany(companyId?: string): Observable<boolean> {
    if (companyId === undefined || companyId === this.currentCompanyId) {
      return of(true);
    }

    return this.authService.isLoggedIn(companyId).pipe(
      mergeMap((isLoggedIn) => {
        if (isLoggedIn) {
          this._currentCompanyId$.next(companyId);
          return of(true);
        }

        return this.automaticallySignin(companyId);
      }),
    )
  }
}
