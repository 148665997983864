import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QrCodeSecretDialogInputData } from './qr-code-secret-dialog.entities';
import { QrCodeService } from 'src/app/services/qr-code.service';
import { map, mergeMap } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SignaturesService } from 'src/app/services/signatures.service';
import { ErrorCodes, PdfService } from 'src/app/services/pdf.service';

@Component({
  selector: 'app-qr-code-secret-dialog',
  templateUrl: './qr-code-secret-dialog.component.html',
  styleUrls: ['./qr-code-secret-dialog.component.scss']
})
export class QrCodeSecretDialogComponent {
  secret: FormControl;
  loading: boolean = false;
  errorMessage: string = null;

  constructor(
    private dialogRef: MatLegacyDialogRef<QrCodeSecretDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: QrCodeSecretDialogInputData,
    private qrCodeService: QrCodeService,
    private pdfService: PdfService,
  ) {
    this.secret = new FormControl('', [Validators.required]);
  }

  submit() {
    if (this.data?.qrCodeLink) {
      this.loading = true;
      this.pdfService.downloadAndValidate(this.data.qrCodeLink, this.secret.value).subscribe({
        next: (result) => {
          this.dialogRef.close({
            secret: this.secret.value,
            url: result.url,
            status: result.status,
          })
          this.loading = false;
        },
        error: (error) => {
          if (error.code === ErrorCodes.INVALID_SECRET) {
            this.secret.setErrors({ wrongSecret: true });
          } else if (error.code === ErrorCodes.DOWNLOAD_ERROR) {
            this.secret.setErrors({ downloadError: true });
          } else if (error.code === ErrorCodes.VALIDATION_ERROR) {
            this.dialogRef.close({
              secret: this.secret.value,
              url: null,
              status: null,
            });
          }
          this.loading = false;
        }
      });
    }
  }

  copyLinkToClipboard(link: string) {
    navigator.clipboard.writeText(link);
  }

  close() {
    this.dialogRef.close(null);
  }
}
