<app-container-header>

  <!-- Content Main -->
  <div contentMain class="company-list">
    <mat-progress-spinner
      *ngIf="companyService.loadingCompanies || selectingCompany; else showPicker"
      diameter="40"
      mode="indeterminate"
      ></mat-progress-spinner>

    <div>
      <ng-template #showPicker>
        <ng-container *ngIf="loggedInCompanies$ | async as loggedInCompanies">
          <app-companies-list
            [companies]="loggedInCompanies"
            title="Selecione a empresa que deseja visualizar"
            [loggedIn]="true"
            buttonLabel="Selecionar"
            (chooseCompany)="chooseCompany($event)"
            ></app-companies-list>
        </ng-container>

        <ng-container *ngIf="loggedOutCompanies$ | async as loggedOutCompanies">
          <app-companies-list
            [companies]="loggedOutCompanies"
            title="Faça o login para acessar"
            [loggedIn]="false"
            buttonLabel="Entrar"
            (chooseCompany)="chooseCompany($event)"
            ></app-companies-list>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <!-- Content Main -->

</app-container-header>
