import { Location } from '@angular/common';
import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import { filter, from, map, mergeMap, Observable, take, toArray } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { SessionService } from '../core/session.service';
import { CompanyService } from '../services/company.service';
import { Company } from '../shared/model/company';

@Component({
  selector: 'app-company-picker',
  templateUrl: './company-picker.component.html',
  styleUrls: ['./company-picker.component.scss']
})
export class CompanyPickerComponent implements OnInit {
  loggedInCompanies$: Observable<Company[]>;
  loggedOutCompanies$: Observable<Company[]>;
  selectingCompany = false;

  private returnUrl: string;
  private regex = /c\/([\w-]+)/;

  constructor(
    public sessionService: SessionService,
    public companyService: CompanyService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private authService: AuthService,
  ) {
    this.loggedInCompanies$ = this.buildCompaniesList(({ isLoggedIn }) => isLoggedIn);
    this.loggedOutCompanies$ = this.buildCompaniesList(({ isLoggedIn }) => !isLoggedIn);
  }

  buildCompaniesList(filterFunction: ({ isLoggedIn, company }) => boolean): Observable<Company[]> {
    return this.companyService.companies$.pipe(
      mergeMap(
        // unwrap companies array items
        (companies) => from(companies).pipe(

          // for each company check if user is logged in at it
          mergeMap((company) => this.authService.isLoggedIn(company.id).pipe(

            // returns a pair of { isLoggedIn: boolean, company: Company }
            map((isLoggedIn) => ({ isLoggedIn, company })),
          )),

          // take the same number of items as in the companies length
          take(companies.length),

          // apply the company filter function
          filter(filterFunction),

          // from the pair, return only the company object
          map(({ isLoggedIn, company }) => company),

          // wrap the items back into an array
          toArray(),
      )),
    );
  }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/c/default';
  }

  chooseCompany(company: Company) {
    this.selectingCompany = true;
    this.sessionService.changeCurrentCompany(company.id)
      .subscribe({
        next: () => {
          const url = this.returnUrl.replace(this.regex, `c/${company.id}`);
          this.router.navigateByUrl(url);
        },
        complete: () => {
          this.selectingCompany = false;
        },
      });
  }

  goBack() {
    this.location.back();
  }
}
