import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SessionService } from '../core/session.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard implements CanActivate {

  constructor(
    private router: Router,
    private sessionService: SessionService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const companyId = route.paramMap.get('company_id');
    return this.sessionService.changeCurrentCompany(companyId).pipe(
      take(1),
      map((success) => {
        return success || this.router.createUrlTree(['login'], {queryParams: {returnUrl: state.url}});
      })
    );
  }
}
