<ng-container *ngIf="companies.length > 0">
    <div class="section-name">{{ title }}</div>
    <mat-action-list class="company-container" [ngClass]="loggedIn ? 'logged-in' : 'logged-out'">
        <ng-container *ngFor="let company of companies">
            <mat-list-item class="company-item" (click)="onChooseCompany(company)">
                <div class="company-first-letter">{{ company.name[0] }}</div>
                <span class="company-name">{{ company.name }}</span>
                <button mat-button color="primary">
                    {{ buttonLabel }}
                    <mat-icon *ngIf="loggedIn">arrow_forward</mat-icon>
                </button>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-action-list>
</ng-container>
