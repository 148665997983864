import { Inject, Injectable } from '@angular/core';
import { getApps } from '@angular/fire/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { addHours, isBefore } from 'date-fns';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { getAuth, SAMLAuthProvider, signInWithCustomToken, signInWithRedirect, UserCredential } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AngularFireAuthFactory } from '../firebase.factory';

export const ERROR_CODES = {
  'auth/invalid-argument': 'Erro: Um argumento inválido foi fornecido.',
  'auth/invalid-disabled-field': 'Erro: O valor fornecido para a propriedade de usuário é inválido.',
  'auth/wrong-password': 'Erro: Usuário ou senha inválidos',
  'auth/admin-restricted-operation': 'Erro: Usuário ainda não cadastrado. Procure o RH da empresa para criar uma conta.',
  'auth/invalid-action-code': 'Esse link de login já expirou ou já foi utilizado. Continue para solicitar um novo link.',
  'auth/internal-error': 'Erro: Não foi possível acessar. Tente novamente.',
  'USER_NOT_FOUND': 'Erro: Email não cadastrado. Procure o RH da sua empresa para solicitar seu cadastro.',
  'RECAPTCHA_FAIL': 'Erro ao validar. Por favor, tente novamente ou fale com o suporte no botão "Ajuda"'
};

export const DEFAULT_EXPIRATION_INTERVAL_HOURS = 168;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    @Inject('angularFireAuthFactory') private angularFireAuthFactory: AngularFireAuthFactory,
  ) {}

  getToken(companyId?: string): Observable<string> {
    return this.getInstance(companyId).idToken;
  }

  getUser(companyId?: string): Observable<firebase.User> {
    return this.getInstance(companyId).user;
  }

  isLoggedIn(companyId?: string): Observable<boolean> {
    return this.getUser(companyId).pipe(
      map((user) => !!user && !user.isAnonymous)
    );
  }

  getInstance(companyId?: string): AngularFireAuth {
    return this.angularFireAuthFactory.getInstance(companyId);
  }

  emailPasswordLogin(email: string, password: string): Observable<firebase.auth.UserCredential> {
    return from(this.angularFireAuth.signInWithEmailAndPassword(email, password));
  }

  emailLinkComplete(email: string, emailLink: string) {
    // The client SDK will parse the code from the link for you.
    return from(this.angularFireAuth.signInWithEmailLink(email, emailLink))
      .pipe(
        tap(() => window.localStorage.removeItem('emailForSignIn')),
      );
  }

  verifyPasswordResetCode(actionCode): Observable<string> {
    return from(this.angularFireAuth.verifyPasswordResetCode(actionCode));
  }

  resetPassword(actionCode: string, newPassword: string): Observable<void> {
    return from(this.angularFireAuth.confirmPasswordReset(actionCode, newPassword));
  }

  signInWithRedirect(companyId: string, tenantId: string, providerId: string): Observable<void> {
    let app: FirebaseApp;
    if (companyId) {
      app = initializeApp(environment.firebase, companyId);
      localStorage.setItem('SAML_LOGIN_COMPANY_ID', companyId);
    } else {
      app = getApp();
    }
    const auth = getAuth(app);
    auth.tenantId = tenantId;
    return from(signInWithRedirect(auth, new SAMLAuthProvider(providerId)));
  }

  signInWithCustomToken(companyId: string, tenantId: string, token: string): Observable<UserCredential> {
    const app = initializeApp(environment.firebase, companyId);
    const auth = getAuth(app);
    auth.tenantId = tenantId;
    return from(signInWithCustomToken(auth, token));
  }

  async logout() {
    await Promise.all(
      getApps().map((app) => getAuth(app).signOut())
    );
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  public errorByCode(code: string): string {
    if (ERROR_CODES[code]) {
      return (ERROR_CODES[code]);
    } else {
      return ('Ocorreu um erro! Tente novamente ou fale com o suporte pelo botão de ajuda.' + (code ? ' Codigo: ' + code : ''));
    }
  }

  /**
   * @deprecated This method should not be used anymore.
   */
  isLoginRecent_deprecated(lastSignIn: string, expirationInterval: number): boolean {
    const signInDate = new Date(lastSignIn),
     expirationDate = addHours(signInDate, expirationInterval);
     return isBefore(new Date(), expirationDate);
  }

  isLoginRecent(authTime: number): boolean {
    return authTime > Date.now();
  }
}
