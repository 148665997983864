<ng-container *ngIf="status !== null">
  <div class="popover-container"
    [ngClass]="{
      'open': open,
      'closed': !open,
      'valid': status === SignatureStatus.VALID,
      'invalid': status !== SignatureStatus.VALID
  }">
    <mat-icon class="popover-icon" (click)="openPopover()">
        <ng-container *ngIf="status === SignatureStatus.VALID">check</ng-container>
        <ng-container *ngIf="status !== SignatureStatus.VALID">warning</ng-container>
    </mat-icon>
    <span class="popover-text">
        <ng-container *ngIf="status === SignatureStatus.VALID">Possui assinatura válida</ng-container>
        <ng-container *ngIf="status !== SignatureStatus.VALID">Assinatura digital inválida/ausente</ng-container>
    </span>
    <mat-icon class="popover-close" (click)="closePopover()">close</mat-icon>
  </div>
</ng-container>
