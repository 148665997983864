import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SessionService } from "../core/session.service";
import { CompanyService } from "../services/company.service";

@Injectable({ providedIn: 'root' })
export class CompanyPickerGuard implements CanActivate {
  constructor(
    private sessionService: SessionService,
    private companyService: CompanyService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.sessionService.currentCompanyId) {
      return this.sessionService.changeCurrentCompany(null);
    }
    return true;
  }
}
