import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Intercom } from 'ng-intercom';
import {
  combineLatest,
  of
} from 'rxjs';
import {
  filter,
  catchError,
  mergeMap,
  takeUntil
} from 'rxjs/operators';

import { BaseClass } from 'src/app/core/base-class';
import { PERMISSIONS } from 'src/app/core/roles';
import { SessionService } from 'src/app/core/session.service';
import { CompanyService } from 'src/app/services/company.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TypesService } from 'src/app/services/types.service';
import { UserService } from 'src/app/services/user.service';
import { Company, Dashboard } from 'src/app/shared/model/company';
import { Permissions } from 'src/app/shared/model/user';
import { environment } from 'src/environments/environment';

import { TYPES } from 'src/app/pages/documents/entities/documents.constants';
import {
  SIDEBAR,
  OCCUPATIONAL,
  CHILDCARE_ASSISTENCE,
  TOP_ITEMS,
  BOTTOM_ITEMS
} from '../entities/sidebar.contants';
import { ESpecificItem } from '../entities/sidebar.enums';
import { IItem } from '../entities/sidebar.interfaces';
import { SidebarService } from '../sidebar.service';
@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.shared.scss', './menu.component.scss'],
})
export class MenuComponent extends BaseClass implements OnInit, AfterViewInit {
  @Input() company: Company;
  @Output() changeProfile = new EventEmitter();
  @Output() showNotifications = new EventEmitter();

  PERMISSIONS = PERMISSIONS;
  appVersion = environment.version;
  specificsItems = ESpecificItem;
  permissions = {};
  sequentialIndex: number = 4;
  companyId: string;
  topItems: IItem[];
  bottomItems: IItem[];

  private dashboards: Dashboard[];
  private showUnfinishedOptions =
    !environment.production ||
    window.location.hostname.includes('beta.closecare.com.br');

  constructor(
    public sessionService: SessionService,
    public sidebarService: SidebarService,
    public notificationService: NotificationService,
    private dashboardService: DashboardService,
    private intercom: Intercom,
    private session: SessionService,
    private userService: UserService,
    private companyService: CompanyService,
    private typesService: TypesService,
  ) {
    super();
  }

  ngOnInit(): void {
    combineLatest([
      this.sessionService.currentCompanyId$,
      this.sessionService.permissions$,
    ]).pipe(
      filter((
        [companyId, { permissions }]: [string, Permissions]
      ) => companyId !== this.companyId)
    ).subscribe((
      [companyId, { permissions }]: [string, Permissions]
    ) => {
      this.companyId = companyId;
      this.permissions = permissions;

      if (companyId) {
        if (this.permissions?.[PERMISSIONS.dashboard_r]) {
          this.dashboardService.dashboards$.subscribe(
            (dashboards: Dashboard[]) => {
              this.dashboards = dashboards;
              this.setMenuItems();
            },
          );
        } else {
          this.setMenuItems();
        }
      }
    });
  }

  ngAfterViewInit(): void {
    const intercomData = {
      app_id: environment.intercomAppId,
      app_name: environment.appName,
      app_version: environment.version,
      hide_default_launcher: true,
      custom_launcher_selector: '#chatButton',
      widget: { activator: '#intercom' },
    };

    this.intercom.boot(intercomData);
    this.userService
      .getUserHmacHash('intercom')
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap((hash: string) =>
          combineLatest([
            of(hash),
            this.session.loggedInUser$,
            this.companyService.currentCompany$,
          ]),
        ),
        catchError(() => []),
      )
      .subscribe(([hash, user, company]) => {
        this.intercom.update({
          ...intercomData,
          user_hash: hash,
          user_id: user?.user_id,
          name: user?.name,
          email: user?.email,
          role: user?.restrictions?.role?.code,
          company: {
            id: company?.id,
            name: company?.name,
          },
        });
      });
  }

  selectNotifications(event): void {
    this.sidebarService.setSpecificItem(ESpecificItem.notification);
    this.showNotifications.emit(event);
  }

  selectProfile(event): void {
    this.sidebarService.setSpecificItem(ESpecificItem.profile);
    this.changeProfile.emit(event);
  }

  private setMenuItems(): void {
    this.typesService.getAllDocumentTypes()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: (response) => {
          const occupational = response.categories
            .find(({ id }) => id === TYPES.occupational.categoryId),

          childcareAssistance = response.categories
            .find(({ id }) => id === TYPES.childcareAssistance.categoryId),

          sideBarTop = SIDEBAR.top,
          items = this.topItems?.[2].children[0].children;

          let occupationalAccess = null,
            childcareAssistanceAccess = null;

          if (occupational?.types) {
            occupationalAccess = occupational.types
              .map((item) => item.permissions.read)
              .includes(true);
          }

          if (childcareAssistance?.types) {
            childcareAssistanceAccess = childcareAssistance.types
              .map((item) => item.permissions.read)
              .includes(true);
          }

          if (occupationalAccess) {
            this.topItems = this.buildMenu(
              this.addDocumentType(items, sideBarTop, OCCUPATIONAL)
            );
          }

          if (childcareAssistanceAccess) {
            this.topItems = this.buildMenu(
              this.addDocumentType(items, sideBarTop, CHILDCARE_ASSISTENCE)
            );
          }

          if (!occupationalAccess && !childcareAssistanceAccess) {
            this.topItems = this.buildMenu(TOP_ITEMS);
          }

          this.bottomItems = this.buildMenu(BOTTOM_ITEMS);
        },
        error: () => {
          this.topItems = this.buildMenu(TOP_ITEMS);
          this.bottomItems = this.buildMenu(BOTTOM_ITEMS);
        }
      });
  }

  private addDocumentType(items, sideBarTop, documentType): IItem[] {
    const exist = items?.some(({ title }) => title === documentType.title);

    if (!this.topItems || !exist) {
      delete sideBarTop.documents.permissions;
      sideBarTop.documents.children[0].children.push(documentType);
    }

    return Object.values(sideBarTop);
  }

  private buildMenu(MENU_SETTINGS: IItem[]): IItem[] {
    return MENU_SETTINGS.map((item: IItem) => {
      if (item.dashboard && !!this.dashboards?.length) {
        item.children = this.dashboards
          .filter(({ id, title }: Dashboard) => id && title)
          .map(({ id, title }: Dashboard) => ({
            routes: ['dashboard', id],
            title,
            allowed: this.permissionsMatch(item.permissions),
          }));
      }

      item = this.setPermission(item);

      return item;
    }).filter(
      (modified: IItem) => this.showUnfinishedOptions || !modified.devOnly,
    );
  }

  private permissionsMatch(permissions: string[]): boolean {
    return permissions.some((permission: string) =>
      Object.keys(this.permissions).includes(permission),
    );
  }

  private setPermission(item: IItem): IItem {
    const allowed =
      !item.permissions || this.permissionsMatch(item.permissions),
      itemChecked = { ...item, allowed };

    if (item.children) {
      itemChecked.children = item.children.map((subItem: IItem) => ({
        ...this.setPermission(subItem),
      }));
    }

    return itemChecked;
  }
}
